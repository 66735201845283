import React, { useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './tasks.css'
import getTasks from 'api/get-tasks'
import TaskModal from './TaskModal/TaskModal'
import Task from 'types/task'
import { AnimatePresence, motion } from 'framer-motion'
import formatNum from 'utils/format-num'
type Props = {}

const Tasks = (props: Props) => {
    const [tasks, settasks] = useState<Task[] | null>(null)
    const [openedTask, setopenedTask] = useState<Task | null>(null)
    const [showComplited, setshowComplited] = useState(false)
    const updateTasks = async () => {
        const result = await getTasks()
        if (result) settasks(result)
    }

    useEffect(() => {
        updateTasks()
    }, [])
  return (
    <PageWrapper>
        <div className="tasks-screen">
        <h2 className="tasks-title-h2">Tasks</h2>
        <div className="tasks-types">
            <div className={`active ${showComplited && 'type-desactive'}`} onClick={() => setshowComplited(false)}>Active</div>
            <div className={`completed ${!showComplited && 'type-desactive'}`} onClick={() => setshowComplited(true)}>Completed</div>
        </div>
            <div className='tasks-items tasks-items-active'>
                <AnimatePresence mode='wait'>
            <motion.div
                key={Number(showComplited)}
                initial={{opacity:0,translateX:50}}
                animate={{opacity:1,translateX:0}}
                transition={{duration:0.1}}
                exit={{opacity:0,translateX:-50}}
                style={{display:'flex',flexDirection:'column',rowGap:14}}
            >
                {tasks?.filter(({status}) => !status).length === 0 && <p>All tasks are completed</p>}
                {tasks && tasks.map((task) => {
                    if (showComplited && !task.status) return null
                    else if (!showComplited && task.status) return null
                    const {id,title,reward,icon,subtitle,reward_type,is_gold_task} = task
                    return (
                        <div className={Number(is_gold_task) ? "tasks-item" : 'completed-item'} key={id} onClick={() => setopenedTask(task)}>
                            <img src={icon} alt="" />
                        <div className="tasks-content">
                            <div className="tasks-title">
                            {title}
                            </div>
                            <div className="tasks-subtitle">
                                {subtitle}
                            </div>
                            <div className="tasks-rep">
                                {reward_type === 'coin' ? <img src="/img/coin4.png"></img> : <span className="big-green">REP</span>} + {formatNum(reward)} points
                            </div>
                        </div>
                        <div className="tasks-arrow">
                            {task.status
                            ? <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.25 15.5L13.75 18L18.75 13M27.5 15.5C27.5 22.4036 21.9036 28 15 28C8.09644 28 2.5 22.4036 2.5 15.5C2.5 8.59644 8.09644 3 15 3C21.9036 3 27.5 8.59644 27.5 15.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg> 
                            
                            : <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.25 23L18.75 15.5L11.25 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>}
                        </div>
                    </div>
                    )
                })}
            </motion.div>
        </AnimatePresence>
            </div>
        </div>
        <TaskModal task={openedTask} openedTask={setopenedTask} refreshTasks={updateTasks}/>
    </PageWrapper>
  )
}

export default Tasks