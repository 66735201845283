import { AnimatePresence, motion } from 'framer-motion'
import './task-inner.css'
import './done-coins.css'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Task from 'types/task'
import formatNum from 'utils/format-num'
import tg from 'utils/tg'
import doneTask, { TaskDoneResponce } from 'api/done-task'
import Icon from 'UI/Icon'
type Props = {
  task:Task | null,
  openedTask:Dispatch<SetStateAction<Task | null>>
  refreshTasks:() => void
}

const TaskModal = ({task,openedTask,refreshTasks}: Props) => {
  const [availableCheck, setAvailableCheck] = useState(false)
  const [claimRewardScreen, setclaimRewardScreen] = useState(false)
  const [loading, setloading] = useState(false)
  const [doneResult, setdoneResult] = useState<TaskDoneResponce>({} as TaskDoneResponce)
  const openUrl = (urL:string) => {
    try {
      tg.openTelegramLink(urL);
    } catch (error) {
      tg.openLink(urL);
    }
    setTimeout(() => setAvailableCheck(true),1000)
  }

  const checkTask = async () => {
    if (!availableCheck || !task) return
    setloading(true)
    const result = await doneTask(task.id)
    await refreshTasks()
    if (result?.success) {
      setdoneResult(result)
      setclaimRewardScreen(true)
    }
    setloading(false)
  } 

  return (
    <AnimatePresence>
      {task && 
      <motion.div 
        initial={{translateY:-100,opacity:0}}
        animate={{translateY:0,opacity:1}}
        exit={{translateY:-100,opacity:0}}
        transition={{ease:'easeInOut'}}
        key={task?.id}
        className="screen-task-inner"
      >
         <div className="close" onClick={() => openedTask(null)}>
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.75 11.25L11.25 18.75M11.25 11.25L18.75 18.75M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
          </div>
          {!claimRewardScreen && <>
          <div className="task-inner-img">
              <img src="/img/tasks-l/x.svg" alt=""/>
          </div>
          <h2 className="task-inner-title">{task.title}</h2>
          <p className="task-inner-text">{task.description}</p>
          <div className="task-inner-money">
              {task.reward_type === 'coin' ? <img src="/img/coin3.png" alt=""/> : <span className="big-green" style={{fontSize:35,marginRight:20}}>REP</span>}
              <div className="task-inner-money-count">+ {formatNum(task.reward)}</div>
          </div>
          { !task.status &&
          <>
            <div className="task-inner-button-go" onClick={() => openUrl(task.url)}>Go</div> 
            <div className="task-inner-button-check" style={{opacity:availableCheck ? 1 : 0.5}} onClick={checkTask}>
          
          {!availableCheck && <CheckIcon/>}
          {loading ? <Icon icon='loading'/> : 'Check'}
          </div>
            </>}</>
          }
           {claimRewardScreen && <>
              <h2 className="done-coins-title">Congratulations!</h2>
                {doneResult?.balance ?
                  <div className="money">
                    <img src="/img/coin4.png" alt="" width="30px" height="30px"/>
                    <span id="money-value">{formatNum(doneResult.balance)}</span>
                  </div>
                  : <div className="money">
                      <span className="big-green" style={{fontSize:25}}>REP</span>
                      <span id="money-value">+ {doneResult.rep}</span>
                    </div>
                  }
                  {doneResult?.balance
                  ? <div className="money-plus">
                      <img src="/img/coin3.png" alt="" width="80px" height="80px" className="coin"/>
                      <span id="money-plus-value">+ {formatNum(task.reward)}</span>
                    </div>
                  : <div className="money-plus">
                      <span className="big-green" style={{fontSize:25}}>REP</span>
                      <span id="money-plus-value">+ {task.reward}</span>
                    </div>
                  }
              <div className="done-button" onClick={() => {
                openedTask(null)
                setclaimRewardScreen(false)
                setAvailableCheck(false)
              }}>
                  Claim Reward
              </div>
            </>}
    </motion.div>}
    </AnimatePresence>
  )
}

const CheckIcon = () => (<svg className="check-svg" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 16.333C2 13.5046 2 12.0904 2.87868 11.2117C3.75736 10.333 5.17157 10.333 8 10.333H16C18.8284 10.333 20.2426 10.333 21.1213 11.2117C22 12.0904 22 13.5046 22 16.333C22 19.1614 22 20.5756 21.1213 21.4543C20.2426 22.333 18.8284 22.333 16 22.333H8C5.17157 22.333 3.75736 22.333 2.87868 21.4543C2 20.5756 2 19.1614 2 16.333Z" stroke="white" stroke-width="1.5"></path>
  <path d="M12 18.333C13.1046 18.333 14 17.4376 14 16.333C14 15.2284 13.1046 14.333 12 14.333C10.8954 14.333 10 15.2284 10 16.333C10 17.4376 10.8954 18.333 12 18.333Z" stroke="white" stroke-width="1.5"></path>
  <path d="M6 10.333V8.33301C6 5.0193 8.68629 2.33301 12 2.33301C15.3137 2.33301 18 5.0193 18 8.33301V10.333" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
</svg>)

export default TaskModal