import en from './en.json';
import ru from './ru.json';

const translations = { en, ru };

// Создаем тип на основе структуры объекта 'en.json'
export type TranslationKeys = typeof en;

// Проверка типов для всех языков
const translationsTyped: Record<string, TranslationKeys> = translations;

export default translationsTyped;
