import claimFarming from 'api/claim-farming'
import startFarming from 'api/start-farming'
import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import UserData from 'types/user-data'
import AppContext from 'utils/app-context'
import getCharacterStyle from 'utils/get-character'
import tg from 'utils/tg'
import { MainContext } from '../../../../../App'

type Props = {
    userData:UserData
}

type FarmingStateProps = {
    farming_coins:number,
    farming_percent:number,
    farming_seconds:number,
    dots:boolean,
}

const FarmingButton = ({userData}: Props) => {
    const {user,setUser,updateUserInfo} = useContext(AppContext)
    const {farming_coins,farming_percent,farming_seconds,farming_status,mining_interval,mining_per_interval,level} = userData
    const dots = true
    const [farmingState,setFarmingState] = useState<null | FarmingStateProps>({farming_coins,farming_percent,farming_seconds,dots})
    const {setNotification} = useContext(MainContext)
    const hours = Math.floor((farmingState?.farming_seconds || 0) / 3600)
    const seconds = Math.floor(((farmingState?.farming_seconds || 0) - (hours * 3600)) / 60)

    const handleComingSoon = () => {
        setNotification({
          id:'',
          text:'COMING SOON...\n\nIn this section, you\'ll be able to purchase unique HERO enhancements, increasingly defining - who is hidden under the mask.',
          user_id:'0',
      })
    }
    const {character,backgroundFog} = getCharacterStyle(level,handleComingSoon)
    const handleFarming = async () => {
        
        let res
        switch (farming_status) {
            case 0:
                res = await startFarming()    
                if (res?.result && user) {
                    await updateUserInfo()
                    setFarmingState({...user,dots:true})
                }
                else tg.showAlert('There was some kind of mistake when starting farming')
            break;
        
             case 2:
                res = await claimFarming()    
                if (res?.result) {
                    updateUserInfo()
                    if(user) setFarmingState(null)
                } else tg.showAlert('There was some kind of mistake when the reward')
            break;
        
            default:
                break;
        }
    }

    useEffect(() => {
        if (!farmingState) return
        if (farmingState?.farming_seconds > 0) {
            const timeout = setTimeout(() => {
                // Обновляем состояние
                const newFarmingState = { ...farmingState };

                // Уменьшаем количество оставшихся секунд
                newFarmingState.farming_seconds-=0.25;

                // Пересчитываем прогресс в процентах
                newFarmingState.farming_percent = ((mining_interval - newFarmingState.farming_seconds) / mining_interval) * 100;

                // Пересчитываем количество нафармленных монет в зависимости от процента
                newFarmingState.farming_coins = ((newFarmingState.farming_percent / 100) * mining_per_interval)

                const afterDot:string | undefined = String(newFarmingState.farming_seconds).split('.')[1]
                
                if (!afterDot || afterDot === '5') {
                    newFarmingState.dots = !newFarmingState.dots
                }

                // Обновляем состояние
                setFarmingState(newFarmingState);
            }, 250);

            return () => clearTimeout(timeout);
        } else {
            updateUserInfo()
        }
    }, [farmingState]);
    return (
    <>
     {!farming_status && <div onClick={handleFarming} className="farming-start" style={{zIndex:3}}>Start Farming</div>}
      <div onClick={handleFarming} className="farming">
        <div className="farming-line-container">
            <div className="farming-line" style={{width:`${farmingState?.farming_percent}%`}}></div>
        </div>
        <div className="farming-reward hideEL">
          <img src="/img/coin4.png" alt="" />
          <div className="farming-reward-count" style={{textAlign:'left'}}>+ {farmingState?.farming_coins.toFixed(farming_status === 2 ? 0 : 4)}</div>
        </div>
        {
           farming_status === 1 && 
           <>
            <p className="farming-text hideEL" style={{width:66,textAlign:'left'}}>Farming...</p>
            <p className="farming-hour hideEL">
                <span id="seconds">{hours.toString().padStart(2, '0')}</span>
                <span style={{opacity:Number(farmingState?.dots)}}>:</span>
                <span id="milliseconds">{seconds.toString().padStart(2, '0')}</span>h
            </p>
           </>
        }
        {farming_status === 2 && <p className="farming-claim hideEL" style={{display:'block'}}>CLAIM</p>}
        {backgroundFog}
        {character}
        {user?.products.map(({layer}) => (
            <img src={layer} className="farming-img"/>
        ))}
      </div></>
  )
}

export default FarmingButton