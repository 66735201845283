import React, { useContext } from 'react'
import Icon from 'UI/Icon'
import mainRoutes from 'routes/main-routes'
import { useLocation, useNavigate } from 'react-router-dom'
import tg from 'utils/tg'
import { MainContext } from '../App'
type Props = {}

const NavBar = (props: Props) => {
    const navigation = useNavigate()
    const location = useLocation();
    const {setNotification} = useContext(MainContext)
    const separatedPath = location.pathname.split('/')
    if (!separatedPath.includes("main") ) return null


    const handleCominSoon = (path?:string) => {
        switch (path) {
            case 'tasks':
                setNotification({
                    id:'',
                    text:'COMING SOON...\n\nIn this section, you can complete interesting tasks and get more Y&N coins and REP points.',
                    user_id:'0',
                })
                break;
        
             case 'upgrade':
                setNotification({
                    id:'',
                    text:'COMING SOON...\n\nIn this section, you\'ll be able to purchase unique HERO enhancements, increasingly defining - who is hidden under the mask.',
                    user_id:'0',
                })
                break;
        
            default:
                break;
        }
    }

  return (
    <div className="nav-menu-items">
    <div className="nav-menu-items-content">
        {mainRoutes.map(({navBarIcon,title,route:{path},comingSoon}) => {
            if (!navBarIcon) return
            const iscurrentRoute = location.pathname === `/main/${path}`
            const handleClick = () => comingSoon ? handleCominSoon(path ) : navigation(`/main/${path}`)
            return (
                <div key={path} className={`nav-menu-item ${iscurrentRoute && 'menu-item-active'}`} onClick={handleClick}>
                    <div className="nav-menu-item-icon">
                        {navBarIcon}
                    </div>
                    <div className="nav-menu-item-title">{title}</div>
                </div>
            )
        })}
    </div>
</div>
  )
}

export default NavBar