import { motion } from 'framer-motion'
import React from 'react'
import Icon from 'UI/Icon'

type Props = {
  navigate:(n:number) => void
  current:number
  loading?: boolean
}

const EntryNav = ({navigate,current,loading}: Props) => {
  return (
    <div className="page-number-nav">
          <motion.button
            style={{opacity:0}}
            // initial={{opacity:0}}
            // animate={{opacity:Number(current)}}
            className="left-arrow" onClick={() => navigate(-1)}>
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L1 7L7 1"
                stroke="white"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </motion.button>
          <p className="page-number">{current+1}/3</p>
          <button className="right-arrow" onClick={() => navigate(1)}>
           {loading
           ? <Icon icon='loading'/>
           :<svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L7 7L1 1"
                stroke="white"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>}
          </button>
        </div>
  )
}

export default EntryNav