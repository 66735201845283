import { Outlet } from "react-router-dom";
import "./styles/main.css";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";


const Main = () => {
 

  return (
    <Outlet />
  );
};

export default Main;
