export default (price:string | number) => {
    price = String(price)
    const s = price.length;
      const chars = price.split('');
      const strWithSpaces = chars.reduceRight((acc, char, i) => {
          const spaceOrNothing = ((((s - i) % 3) === 0) ? ' ' : '');
          return (spaceOrNothing + char + acc);
      }, '');
  
      return ((strWithSpaces[0] === ' ') ? strWithSpaces.slice(1) : strWithSpaces);
  }
  