import { useContext, useEffect } from "react";
import PageWrapper from "UI/PageWrapper";
import AppContext from "utils/app-context";
import formatNum from "utils/format-num";
import FarmingButton from "./components/FarmingButton";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../../App";

type Props = {};

const Home = (props: Props) => {
  const {user,updateUserInfo} = useContext(AppContext)
  const navigate = useNavigate()
  const {setNotification,updateAlert} = useContext(MainContext)
 

  const handleComingSoon = () => {
    setNotification({
      id:'',
      text:'COMING SOON...\n\nIn this section, you\'ll be able to purchase unique HERO enhancements, increasingly defining - who is hidden under the mask.',
      user_id:'0',
    })
  }
  useEffect(() => {
    updateUserInfo()
  }, [])
  useEffect(() => {
    updateAlert()
  }, [])
  if (!user) return null
  const {for_next_level,balance,rep,level} = user
  
  return (
    <PageWrapper>
      <div className="screen">
        <div className="main-level">
          <div className="main-level-info">
            <div className="main-level-info-rep">
              REP: <span className="thing">{level}</span>
            </div>
            <div className="main-level-info-next">
              Next level: {formatNum(rep)} / {formatNum(for_next_level)} points of REP
            </div>
          </div>
          <div className="main-info-bar">
            <div className="main-info-line" style={{width:`${100 * rep / for_next_level }%`}}></div>
          </div>
          <div className="coinsCount">
            <div style={{gap:4,display:'flex',alignItems:'center'}}>
            <img src="\img\coin3.png" alt="" />
            <div className="count">{formatNum(balance)}</div>
            </div>
            {/* <div className="coinsCount-button" onClick={() => navigate('/main/upgrade')}>Upgrade</div> */}
            <div className="coinsCount-button" onClick={() => handleComingSoon()}>Upgrade</div>
          </div>
        </div>
      </div>
     <FarmingButton userData={user}/>
    </PageWrapper>
  );
};

export default Home;
