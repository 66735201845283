import tg from "utils/tg";
import UserData from "types/user-data";
const tg_id = tg.initDataUnsafe.user?.id;

interface Responce {
  result: boolean;
  data: RegData;
}

export interface RegData {
  years: number;
  rep: number;
  count: number;
  reward: number;
}
export default async (lang:string):Promise<RegData | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "settings";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("lang", String(lang));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
