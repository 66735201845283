import { RouteObject } from "react-router";
import Loading from "pages/Loading/Loading";
import Entry from "pages/Entry/Entry";
import Main from "pages/Main/Main";
import DailyReward from "pages/DailyReward/DailyReward";
import mainRoutes from './main-routes'

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Loading />,
  },
  {
    path: "/entry",
    element: <Entry />,
  },
  {
    path: "daily-reward",
    element: <DailyReward />,
  },
  {
    path: "/main",
    element: <Main />,
    children: mainRoutes.map((r) => r.route)
  },
];
export default routes;
