import React, { Dispatch, SetStateAction, useState } from 'react'
import './upgrade-cart-standart.css'
import Product from 'types/product'
import { AnimatePresence, motion } from 'framer-motion'
import buyProduct from 'api/buy-product'
import Icon from 'UI/Icon'
import formatNum from 'utils/format-num'
type Props = {
  product:Product | null
  setProduct:Dispatch<SetStateAction<Product | null>>
  refreshList:() => void
}

const UpgradeModal = ({product,setProduct,refreshList}: Props) => {
  const [loading, setloading] = useState(false)
  
  const handleBuyProduct = async () => {
    setloading(true)
    const result = await buyProduct(product?.id || '')
    await refreshList()
    setProduct(null)
    setloading(false)
  }
  
  return (
    <AnimatePresence>
    {product && <motion.div 
        initial={{translateY:-100,opacity:0}}
        animate={{translateY:0,opacity:1}}
        exit={{translateY:-100,opacity:0}}
        transition={{ease:'easeInOut'}}
        key={product?.id}
        className="upgrade-modal-screen"
      >
        <>
        <div className="up-standart-header">
            <h2 className="title">Standart skin</h2>
            <svg onClick={() => setProduct(null)} className="close" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.75 10.25L10.25 17.75M10.25 10.25L17.75 17.75M26.5 14C26.5 20.9036 20.9036 26.5 14 26.5C7.09644 26.5 1.5 20.9036 1.5 14C1.5 7.09644 7.09644 1.5 14 1.5C20.9036 1.5 26.5 7.09644 26.5 14Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        </div>
        <div className="up-standart-image">
            <img src="/img/standart-card.png" alt=""/>
        </div>
        <p className="up-standart-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        <div className="upgrade-farming">
            <div className="farming-coin">
                <img src="/img/coin4.png" alt=""/>
                <div className="farming-coin-text"><span className="bold">Farming:</span>x20</div>
            </div>
            <div className="farming-rep">
                <div className="farming-rep-text"><span className="green-bold">REP:</span>+1 000 points</div>
            </div>
        </div>
        {
        loading
          ? <div className="up-standart-button"><Icon icon='loading'/></div>
          : <div className="up-standart-button" onClick={handleBuyProduct} style={{justifyContent:'space-around'}}>
              {!!product.price_coins && <div style={{display:'flex',alignItems:'center',gap:5}}><img src="/img/coin4.png" alt=""/>{formatNum(product.price_coins)}</div>}
              {!!product.price_stars && <div style={{display:'flex',alignItems:'center',gap:5}}><img src="/img/star.svg" alt=""/>{formatNum(product.price_stars)}</div>}
              {!!product.price_ton && <div style={{display:'flex',alignItems:'center',gap:5}}><img src="/img/rubin.svg" alt=""/>{formatNum(product.price_ton)}</div>}
            </div>
        }
        </>
        </motion.div>}
    </AnimatePresence>
  )
}

export default UpgradeModal