import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import "./onboarding-style.css";
import PageWrapper from 'UI/PageWrapper';
import AppContext from 'utils/app-context';
import getDailyReward from 'api/get-daily-reward';
import tg from 'utils/tg';

type Props = {}

const DailyReward = (props: Props) => {
  const navigation = useNavigate()
  const {user} = useContext(AppContext)
  if(!user) return null

  if (!user.everyday_task) {
    navigation('/main/home')
    return null
  }
  const {day,reward,text} = user.everyday_task

  const getReward = async () => {
    const res = await getDailyReward()
    if (!res?.success) tg.showPopup({message:'Some mistake when receiving a daily award'})
    navigation('/main/home')
  }

  return (
    <PageWrapper className="screen reward-screen">
        <h2 className="reward-day">Day {day}</h2>
        <p className="reward-text">You Daily <br/> Reward</p>
        <div className="reward">
            <img src="img/coin-2.png" alt=""/>
            <div className="reward-count">+ {reward}</div>
        </div>
        <p className="reward-text-2">{text}</p>
        <div className="reward-button" onClick={getReward} >Get Reward</div>
    </PageWrapper>
  )
}

export default DailyReward