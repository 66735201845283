import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;

export interface ProductBuyResponce {
  result: boolean;
}
export default async (product_id:string | number):Promise<ProductBuyResponce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "product_buy";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("product_id", String(product_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
