import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import "./onboarding-style.css";
import EntryNav from "./EntryNav";
import { motion } from "framer-motion";
import EntryScreenFirst from "./screens/EntryScreenFirst";
import EntryScreenSecond from "./screens/EntryScreenSecond";
import EntryScreenThird from "./screens/EntryScreenThird";
import { useNavigate } from "react-router-dom";
import sendSettings, { RegData } from "api/send-settings";
import AppContext from "utils/app-context";
type Props = {};

const Entry = (props: Props) => {
  const [page, setpage] = useState(0);
  const navigation = useNavigate()
  const [loading, setloading] = useState(false)
  const [regState, setregState] = useState<RegData | null>(null)
  console.log("🚀 ~ Entry ~ regState:", regState)
  const {t,lang,user} = useContext(AppContext)
  if (!user) return null
  const goToPage = async (action: number) => {
    const res = page + action;
    if (res < 0 || res > 2) {
      if (res > 2) {
        let path = ''
        if (user.everyday_task) path = '/daily-reward'
        else path = '/main/home'
        navigation(path);
      }
      return;
  }
    if (res == 1) {
      setloading(true)
      await handlesetSettings()
      setloading(false)
    }
    setpage(res);
  };

  const handlesetSettings = async () => {
    const result = await sendSettings(lang)
    if (result) {
      setregState(result)
    }
  }

  return (
    <PageWrapper>
      <div className="status-bar">
        <motion.div className={`line-screen-${page+1}`} />
      </div>
      <div className="screens-container">
        <motion.div
          className="screens-track"
          animate={{ translateX: `${page * -33.33}%` }}
          transition={{ ease: "easeInOut",duration:0.2 }}
        >
          <EntryScreenFirst/>
          <EntryScreenSecond age={regState?.years} reward={regState?.rep}/>
          <EntryScreenThird count={regState?.count} reward={regState?.reward}/>
        </motion.div>
      </div>

      <EntryNav navigate={goToPage} current={page} loading={loading}/>
    </PageWrapper>
  );
};

export default Entry;
