import Transaction from "types/transaction";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;


export type TransactionFilter = 'rep' | 'balance' | 'ton' | 'stars'

export type TransactionDirection = 'credit' | 'debit'

export default async (filters?:TransactionFilter[],directions?:TransactionDirection):Promise<Transaction[] | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "transactions";
    API_URL.searchParams.set("tg_id", String(tg_id));
    if (filters) filters.forEach(f => {
      API_URL.searchParams.append("filters[]", String(f))
    })
    if (directions) API_URL.searchParams.set("directions", String(directions))

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
