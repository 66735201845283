import React, { useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './wallet.css'
import getTransactions, { TransactionDirection, TransactionFilter } from 'api/get-transactions'
import Transaction from 'types/transaction'
import formatNum from 'utils/format-num'
type Props = {}

const Wallet = (props: Props) => {
    const [transactions, settransactions] = useState<Transaction[] | null>(null)
    const [filters, setfilters] = useState<TransactionFilter[]>([])
    const [direction, setdirection] = useState<TransactionDirection | undefined>(undefined)
    
    const updateTransactions = async () => {
        const result = await getTransactions(filters,direction)
        if (result) settransactions(result)
    }

    const setFilters = (newFilter:TransactionFilter) => {
        if (filters.includes(newFilter)) setfilters(old => old.filter(str => str !== newFilter))
        else setfilters(old => [...old,newFilter])
    } 
    
    useEffect(() => {
        updateTransactions()
    }, [filters,direction])
  return (
    <PageWrapper>
        <div className="screen">
    <div className="wal-header" style={{display:'none'}}>
        <div className="wal-header-name">
            <div className="user-name">Jack</div>
            <img src="/img/pen.svg" alt="" className="edit"/>
        </div>
        <div className="input-container">
            <input type="text" className="edit-input"/>
            <img src="/img/edit.svg" alt="" className="close-edit"/>
        </div>
        <div className="user-name-save-button">
            Save
        </div>
    </div>
    <div className="wal-selects" style={{display:'none'}}>
        <div className="custom-select-wrapper">
            <div className="custom-select">
                <div className="custom-select-trigger">English</div>
                <div className="custom-select-arrow">
                    <img src="/img/select-arrow-down.svg" alt="" className="arrow-down"/>
                    <img src="/img/select-arrow-top.svg" alt="" className="arrow-top"/>
                </div>
                <div className="custom-options">
                    <span className="custom-option" data-value="en">English</span>
                    <span className="custom-option" data-value="ru">Русский</span>
                </div>
            </div>
        </div>
        <div className="custom-select-wrapper2">
            <div className="custom-select2">
                <div className="custom-select-trigger2">Russia</div>
                <div className="custom-select-arrow2">
                    <img src="/img/select-arrow-down.svg" alt="" className="arrow-down2"/>
                    <img src="/img/select-arrow-top.svg" alt="" className="arrow-top2"/>
                </div>
                <div className="custom-options2">
                    <span className="custom-option2" data-value="russia">Russia</span>
                    <span className="custom-option2" data-value="russia">England</span>
                </div>
            </div>
        </div>
    </div>
    <button className="wal-selects-button" id="save-button" style={{display:'none'}}>Save</button>
    <button className="wal-selects-button2" style={{display:'none'}}>Save</button>
    <div className="wal-connected" style={{display:'none'}}>
        <h3 className="wal-connected-title">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 10H12.5" stroke="#9C59CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M26.0416 11.25H22.7885C20.5581 11.25 18.75 12.9289 18.75 15C18.75 17.0711 20.5581 18.75 22.7885 18.75H26.0416C26.1459 18.75 26.1979 18.75 26.2419 18.7474C26.916 18.7063 27.4529 18.2078 27.4971 17.5818C27.5 17.5409 27.5 17.4925 27.5 17.3959V12.6041C27.5 12.5075 27.5 12.4591 27.4971 12.4182C27.4529 11.7923 26.916 11.2937 26.2419 11.2527C26.1979 11.25 26.1459 11.25 26.0416 11.25Z" stroke="#9C59CC" strokeWidth="1.5"/>
                <path d="M26.2063 11.25C26.1091 8.90962 25.7957 7.47469 24.7855 6.46446C23.3211 5 20.964 5 16.25 5H12.5C7.78595 5 5.42894 5 3.96446 6.46446C2.5 7.92894 2.5 10.286 2.5 15C2.5 19.714 2.5 22.0711 3.96446 23.5355C5.42894 25 7.78595 25 12.5 25H16.25C20.964 25 23.3211 25 24.7855 23.5355C25.7957 22.5254 26.1091 21.0904 26.2063 18.75" stroke="#9C59CC" strokeWidth="1.5"/>
                <path d="M22.4888 15H22.5" stroke="#9C59CC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Wallet connected
        </h3>
        <div className="wal-connected-actions">
            <div className="wal-connected-address">
                Address
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.5001 6H3.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M18.8332 8.5L18.3732 15.3991C18.1962 18.054 18.1077 19.3815 17.2427 20.1907C16.3777 21 15.0473 21 12.3865 21H11.6132C8.95235 21 7.62195 21 6.75694 20.1907C5.89194 19.3815 5.80344 18.054 5.62644 15.3991L5.1665 8.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6" stroke="white" strokeWidth="1.5"/>
                </svg>
            </div>
            <div className="wal-connected-staking">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16Z" stroke="white" strokeWidth="1.5"/>
                    <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="white" strokeWidth="1.5"/>
                    <path d="M6 10V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
                Staking
            </div>
        </div>

    </div>
    <div className="wal-transaction">
        <h3 className="wal-connected-title">
            Transaction history:
        </h3>
        <div className="wal-transaction-actions">
            <div className="radio-item">
                <label>
                    <input type="checkbox" name="option" checked={filters.includes('stars')} onChange={() => setFilters("stars")}/> Stars Transactions
                </label>
            </div>
            <div className="radio-item">
                <label>
                    <input type="checkbox" name="option" checked={filters.includes('balance')} onChange={() => setFilters("balance")}/> Y&N Transactions
                </label>
            </div>
            <div className="radio-item">
                <label>
                    <input type="checkbox" name="option" checked={filters.includes('ton')} onChange={() => setFilters("ton")}/> TON Transactions
                </label>
            </div>
            <div className="radio-item">
                <label>
                    <input type="checkbox" name="option" checked={filters.includes('rep')} onChange={() => setFilters("rep")}/> REP Transactions
                </label>
            </div>
        </div>
    </div>
    <div className="wal-cards">
        <div className={`card ${direction === undefined && 'card-active'}`} onClick={ () => setdirection(undefined)}>ALL</div>
        <div className={`card ${direction === 'credit' && 'card-active'}`} onClick={ () => setdirection('credit')}>CREDIT</div>
        <div className={`card ${direction === 'debit' && 'card-active'}`} onClick={ () => setdirection('debit')}>DEBIT</div>
    </div>
    <div className="history">
            {!transactions?.length && <div style={{margin:'30px 0 150px'}}>The history of transactions is empty, try to change filtering settings</div>}
            {transactions && transactions.map(({amount,coin,comment,created_at},i) => {
                const currencies:Record<typeof coin,string> = {
                    balance:'Y&N',
                    rep:'REP',
                    ton:'TON',
                    stars:'Stars',
                }
                const isrep = coin === 'rep'
                return (
                <div className="history-item" key={i}>
                    <div className="history-info">
                        <p className="lavel">{isrep ? <span className="big-green">{comment}</span> : comment}</p>
                        <p className="date">{created_at}</p>
                    </div>
                    <div className="history-sum">{
                    isrep
                        ? <span className="green">{`${amount >= 0 ? '+' : ''} ${formatNum(amount)} ${currencies[coin]}`}</span>
                        : `${amount >= 0 ? '+' : ''} ${formatNum(amount)} ${currencies[coin]}`
                    }</div>
                </div>
            )})}
    </div>
    </div>
</PageWrapper>
  )
}

export default Wallet